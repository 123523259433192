import axios from 'axios'
import * as React from 'react'
import { useEffect, useState } from 'react'
import HallSessions from './HallSessions'

export const HALL_CATEGORY_1 : Array<ProgrammeSession['hall']> = ['Sabah Hall']
export const HALL_CATEGORY_2 : Array<ProgrammeSession['hall']> = ['Kedah Room']
export const HALL_CATEGORY_3 : Array<ProgrammeSession['hall']> = ['Melaka Room']
export const HALL_CATEGORY_4 : Array<ProgrammeSession['hall']> = ['Selangor Room']
export const HALL_CATEGORY_5 : Array<ProgrammeSession['hall']> = ['EHA Pre-Congress Tutorial']
export const HALL_CATEGORY_6 : Array<ProgrammeSession['hall']> = ['MSH Pre-Congress Pharmacy Programme']

const EVENT_DATES = [
  { dayNum: "pre_1", dayName: "Pre-Congress", date: '(17th Apr 2024)' },
  { dayNum: "pre_2", dayName: "Pre-Congress", date: '(18th Apr 2024)' },
  { dayNum: 1, dayName: "Day 1", date: '(18th Apr 2024)' },
  { dayNum: 2, dayName: "Day 2", date: '(19th Apr 2024)' },
  { dayNum: 3, dayName: "Day 3", date: '(20th Apr 2024)' },
]

export const FACULTY_TYPE = [
  'Chairperson',
  'Citation',
  'Co-Chair',
  'Emcee',
  'Judge',
  'Guest Of Honor',
  'Speaker',
  'Adjudicator',
  'Moderator',
  'Facilitator',
  'Facilitators',
] as const

export enum CATEGORY {
  'clinical_1',
  'clinical_2',
  'nursing',
  'pathology',
  'eha_pre_tutorial',
  'msh_pre_pharmacy'
}

export interface Faculty {
  id: number
  fullName: string
  avatar: string
  shortProfile: string
}

export interface FacultyWithType extends Faculty {
  facultyType: typeof FACULTY_TYPE[number]
}

export interface ProgrammeSession {
  id: number
  title: string
  desc?: string
  startAt: string
  endAt: string
  date: string // 11 JUN | SATURDAY
  time: string // 10:00 to 11:00
  day: 1 | 2 | 3 | 'pre_1' | 'pre_2'
  hallOverwrite?: string
  hall: 'Sabah Hall' | 'Kedah Room' | 'Melaka Room' | 'Selangor Room' | 'EHA Pre-Congress Tutorial' | 'MSH Pre-Congress Pharmacy Programme'
  category:  'clinical_1' | 'clinical_2' | 'nursing' | 'pathology' | 'eha_pre_tutorial' | 'msh_pre_pharmacy'
  faculties: Array<FacultyWithType>
  subsessions: Array<ProgrammeSubsession>
  secondsTillStart: number
  durationInSeconds: number
  ended?: boolean
}

export interface ProgrammeSubsession {
  id: number
  title?: string
  description?: string
  speakers: Array<Faculty>
  time: string // 10:00 to 11:00
}

const Programme = ({ defaultDay }) => {
  const [loading, setLoading] = useState(false)
  const [category, setCategory] = useState<CATEGORY>(CATEGORY.eha_pre_tutorial)
  const [day, setDay] = useState('pre_1' || defaultDay || 1)
  const [data, setData] = useState([])

  const [activeLecture, setActiveLecture] = useState<ProgrammeSession['hall']>('EHA Pre-Congress Tutorial')

  

  useEffect(() => {
    setLoading(true)
    axios
      .get(
        `/api/programme_sessions?category=${CATEGORY[category]}&day=${day}`
      )
      .then((response) => {
        setData(response.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [day, category])

  const dayClass = 'btn btn-outline-red my-1 fw-bold me-2'
  const catClass = 'btn btn-outline-danger my-1 fw-bold me-2'

  const getTabColor = (hallname: string) => {
    switch (hallname) {
      case 'EHA Pre-Congress Tutorial':
        return '#f9d5e5';
      case 'MSH Pre-Congress Pharmacy Programme':
        return '#e4ff87';
      case 'Sabah Hall':
        return '#fcb1b1';
      case 'Kedah Room':
        return '#59d4e8';
      case 'Melaka Room':
        return '#96f7d2';
      case 'Selangor Room':
        return '#a6acec';
      default:
        return '';
    }
  }


  if (loading) {
    return (
      <div className="loader">
        <div className="spinner"></div>
      </div>
    )
  }

  return (
    <div className="container pb-5" id="programmeAgenda">
      <div className="filterBtns">

        {/* 1st row */}
        <div className="my-3 text-center">
          {EVENT_DATES.map(({ dayNum, dayName, date }) => (
            <button
              key={dayNum}
              className={`${dayNum === day && 'active'} ${dayClass}`}
              onClick={() => {
                setDay(dayNum)
                if (dayNum === 1 || dayNum === 2 || dayNum === 3) {
                  setCategory(CATEGORY.clinical_1);
                  setActiveLecture('Sabah Hall');
                }
                else if (dayNum === 'pre_1' || dayNum === 'pre_2') {
                  setCategory(CATEGORY.eha_pre_tutorial);
                  setActiveLecture('EHA Pre-Congress Tutorial');
                }
              }}
            >
              <div>{dayName}</div>
              <div>{date}</div>
            </button>
          ))}
        </div>

        {/* 2nd row */}
        {(day === 'pre_1' || day === 'pre_2') && (
          <div className="my-3 text-center">
            <button
              className={`${category === CATEGORY.eha_pre_tutorial && 'active'} ${catClass}`}
              onClick={() => {
                setCategory(CATEGORY.eha_pre_tutorial);
                setActiveLecture('EHA Pre-Congress Tutorial');
              }}
            >
              <div>EHA Pre-Congress</div> 
              <div>Tutorial</div>
            </button>
            <button
              className={`${category === CATEGORY.msh_pre_pharmacy && 'active'} ${catClass}`}
              onClick={() => {
                setCategory(CATEGORY.msh_pre_pharmacy);
                setActiveLecture('MSH Pre-Congress Pharmacy Programme');
              }}            >
              <div>MSH Pre-Congress</div>
              <div>Pharmacy Programme</div>
            </button>
          </div>
        )}

        {(day === 1 || day === 2 || day === 3 )&& (
          <div className="my-3 text-center">
            <button
              className={`${category === CATEGORY.clinical_1 && 'active'} ${catClass}`}
              onClick={() => {
                setCategory(CATEGORY.clinical_1);
                setActiveLecture('Sabah Hall');
              }}
            >
              Clinical 1 | Sabah Hall
            </button>
            <button
              className={`${category === CATEGORY.clinical_2 && 'active'} ${catClass}`}
              onClick={() => {
                setCategory(CATEGORY.clinical_2);
                setActiveLecture('Kedah Room');
              }}
            >
              Clinical 2 | Kedah Room
            </button>
            <button
              className={`${category === CATEGORY.nursing && 'active'} ${catClass}`}
              onClick={() => {
                setCategory(CATEGORY.nursing);
                setActiveLecture('Melaka Room');
              }}              
            >
              Nursing | Melaka Room
            </button>
            <button
              className={`${category === CATEGORY.pathology && 'active'} ${catClass}`}
              onClick={() => {
                setCategory(CATEGORY.pathology);
                setActiveLecture('Selangor Room');
              }} 
            >
              Pathology | Selangor Room
            </button>
          </div>
        )}
      </div>

      {/* 3rd row */}
      <div className='col-12 col-lg-8 mx-auto'>
        {category === CATEGORY.eha_pre_tutorial && (
          <div className="mt-5">
            
            <div className="d-block d-md-none">
              {HALL_CATEGORY_5.map((hall) => (
                <button
                  key={hall}
                  className={`hallTabs fw-bold ${
                    activeLecture !== hall && 'deselected'
                  }`}
                  style={{background: getTabColor(hall), textTransform: 'capitalize' }}
                  onClick={() => setActiveLecture(hall)}
                >
                  {hall.replace('Lecture ','')}
                </button>
              ))}
            </div>
            
            <div className="row nogutters">
              {HALL_CATEGORY_5.map((hall) => (
                // <div className={day === 2 ? "col-md-6" : "col-md-4 px-2"} key={hall}>
                <div key={hall}>
                  <HallSessions
                    hallName={hall}
                    activeHall={activeLecture}
                    programmes={data.filter(
                      (programme: ProgrammeSession) => programme.hall === hall
                    )}
                    getTabColor={getTabColor}
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        {category === CATEGORY.msh_pre_pharmacy && (
          <div className="mt-5">

            <div className="d-block d-md-none">
              {HALL_CATEGORY_6.map((hall) => (
                <button
                  key={hall}
                  className={`hallTabs fw-bold ${
                    activeLecture !== hall && 'deselected'
                  }`}
                  style={{background: getTabColor(hall), textTransform: 'capitalize' }}
                  onClick={() => setActiveLecture(hall)}
                >
                  {hall.replace('Lecture ','')}
                </button>
              ))}
            </div>
            
            <div className="row nogutters">
              {HALL_CATEGORY_6.map((hall) => (
                // <div className={day === 2 ? "col-md-6" : "col-md-4 px-2"} key={hall}>
                <div key={hall}>
                  <HallSessions
                    hallName={hall}
                    activeHall={activeLecture}
                    programmes={data.filter(
                      (programme: ProgrammeSession) => programme.hall === hall
                    )}
                    getTabColor={getTabColor}
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        {category === CATEGORY.clinical_1 && (
          <div className="mt-5">
            
            <div className="d-block d-md-none">
              {HALL_CATEGORY_1.map((hall) => (
                <button
                  key={hall}
                  className={`hallTabs fw-bold ${
                    activeLecture !== hall && 'deselected'
                  }`}
                  style={{background: getTabColor(hall), textTransform: 'capitalize' }}
                  onClick={() => setActiveLecture(hall)}
                >
                  {hall.replace('Lecture ','')}
                </button>
              ))}
            </div>
            
            <div className="row nogutters">
              {HALL_CATEGORY_1.map((hall) => (
                // <div className={day === 2 ? "col-md-6" : "col-md-4 px-2"} key={hall}>
                <div key={hall}>
                  <HallSessions
                    hallName={hall}
                    activeHall={activeLecture}
                    programmes={data.filter(
                      (programme: ProgrammeSession) => programme.hall === hall
                    )}
                    getTabColor={getTabColor}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
  
        {category === CATEGORY.clinical_2 && (
          <div className="mt-5">
            
            <div className="d-block d-md-none">
              {HALL_CATEGORY_2.map((hall) => (
                <button
                  key={hall}
                  className={`hallTabs fw-bold ${
                    activeLecture !== hall && 'deselected'
                  }`}
                  style={{background: getTabColor(hall), textTransform: 'capitalize' }}
                  onClick={() => setActiveLecture(hall)}
                >
                  {hall.replace('Lecture ','')}
                </button>
              ))}
            </div>
            
            <div className="row nogutters">
              {HALL_CATEGORY_2.map((hall) => (
                // <div className={day === 2 ? "col-md-6" : "col-md-4 px-2"} key={hall}>
                <div key={hall}>
                  <HallSessions
                    hallName={hall}
                    activeHall={activeLecture}
                    programmes={data.filter(
                      (programme: ProgrammeSession) => programme.hall === hall
                    )}
                    getTabColor={getTabColor}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        
        {category === CATEGORY.nursing && (
          <div className="mt-5">
            
            <div className="d-block d-md-none">
              {HALL_CATEGORY_3.map((hall) => (
                <button
                  key={hall}
                  className={`hallTabs fw-bold ${
                    activeLecture !== hall && 'deselected'
                  }`}
                  style={{background: getTabColor(hall), textTransform: 'capitalize' }}
                  onClick={() => setActiveLecture(hall)}
                >
                  {hall.replace('Lecture ','')}
                </button>
              ))}
            </div>
            
            <div className="row nogutters">
              {HALL_CATEGORY_3.map((hall) => (
                // <div className={day === 2 ? "col-md-6" : "col-md-4 px-2"} key={hall}>
                <div key={hall}>
                  <HallSessions
                    hallName={hall}
                    activeHall={activeLecture}
                    programmes={data.filter(
                      (programme: ProgrammeSession) => programme.hall === hall
                    )}
                    getTabColor={getTabColor}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        
        {category === CATEGORY.pathology && (
          <div className="mt-5">
            
            <div className="d-block d-md-none">
              {HALL_CATEGORY_4.map((hall) => (
                <button
                  key={hall}
                  className={`hallTabs fw-bold ${
                    activeLecture !== hall && 'deselected'
                  }`}
                  style={{background: getTabColor(hall), textTransform: 'capitalize' }}
                  onClick={() => setActiveLecture(hall)}
                >
                  {hall.replace('Lecture ','')}
                </button>
              ))}
            </div>
            
            <div className="row nogutters">
              {HALL_CATEGORY_4.map((hall) => (
                // <div className={day === 2 ? "col-md-6" : "col-md-4 px-2"} key={hall}>
                <div key={hall}>
                  <HallSessions
                    hallName={hall}
                    activeHall={activeLecture}
                    programmes={data.filter(
                      (programme: ProgrammeSession) => programme.hall === hall
                    )}
                    getTabColor={getTabColor}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

    </div>
  )
}

export default Programme
